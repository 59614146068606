.mainContainer{
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 127px;
    padding-bottom: 127px;
}
.mainPageCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 25px;
    box-shadow: 5px 5px 5px 2px #6b7280;
}

.faqHeading {
    display: flex;
    flex-direction: row;
    font-size: 42px;
    font-weight: 800;
    line-height: normal;
    justify-content: center;
    align-items: center;
}

.faqIcon {
    display: inline-block;
    margin-right: 20px;
}

.faqContainer {
    margin-top: 40px;
    margin-bottom: 40px;
}

.faqContainer details {
    width: 100%;
    border: 1px solid #F15223;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 12px;
}

.faqContainer details summary {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    font-weight: 600;
    color: #F15223;
}

.faqContainer details p {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 16px;
    margin-top: 16px;
    padding-top: 0px;
    color: #4b5563;
}

.hightLightText {
    color: #F15223;
    font-weight: 700;
    text-decoration: none;
}

.helpParagraph {
    padding-bottom: 24px;
    font-weight: 600;
    color: #4b5563;
}

.semiBold{
    font-weight: 600;
}

.semiboldUnderline{
    font-weight: 600;
    text-decoration: underline;
}

.faqList{
    list-style: decimal;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
}



@media (max-width: 762px) {
    .mainContainer{
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .mainPageCard {
        padding-left: 12px;
        padding-right: 12px;
    }

    .faqHeading {
        flex-direction: column;
        font-size: 24px;
        text-align: center;
    }

    .faqIcon {
        display: block;
        margin-right: 0px;
    }

}