.heroBgImage {
    background-image: url('../assets/images/DTPHerobig.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    width: 100%;
    height: 90vh;
    padding-top: 150px;
    z-index: -2;
}

.heroBackground {
    width: 100%;
    height: 100vh;
    padding-top: 100px;
    background: linear-gradient(90deg, #fff 0%, #fff 50%, #f15223 50%);
}

.emptyContainer {
    width: 60%;
    height: 90vh;
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 90vh;
    left: 0;
    top: 5%;
    padding: 20px;
}

.imageBgc {
    position: absolute;
    top: 48%;
    left: 60%;
    transform: translate(-60%, -50%);
    width: 60%;
    height: 75%;
    background-color: #ffd685;
    z-index: 1;
    box-shadow: 3px 5px 3px 2px #5f5f5f;
}

.heroImg {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 90%;
}

.altHomeHeroInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1300px;
    margin-top: -50px;
    margin-right: auto;
    margin-left: auto;
}

.homeHeroInnerContainer {
    height: 90vh;
    margin-top: -150px;
    padding-top: 100px;
    padding-bottom: 100px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%,
            rgba(255, 255, 255, 0.6) 40%,
            rgba(255, 255, 255, 0.4) 43%,
            rgba(255, 255, 255, 0.3) 44%,
            rgba(255, 255, 255, 0.2) 45%,
            rgba(255, 255, 255, 0.1) 46%,
            rgba(255, 255, 255, 0) 100%);

}

.maxScreen {
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 100px;
}

.heroImage {
    width: 100%;
}

.altHeroContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: 90vh;
}

.headingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 50px;
    width: 450px;
    height: 90vh;
}

.heading {
    font-size: 48px;
    line-height: 57px;
    font-weight: 700;
    color: #000;
}

.heroContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 30px;
    width: 600px;
    padding-top: 150px;
}

.paragraph {
    font-size: 18px;
    text-align: left;
    font-weight: normal;
    line-height: 30px;
    padding-top: 10px;
    padding-right: 50px;
    color: #000;
}

.startTrackingButton {
    background-color: #F15223;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
    margin-right: 10px;
    padding: 12px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: none;
    border-radius: 25px;
    box-shadow: 1px 2px 6px 1px #373a3f;
    cursor: pointer;
    max-width: 70%;
}

@media (max-width: 1299px) {
    .heroBgImage {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        width: 100%;
        margin-right: -150px;
    }

    .headingContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 20px;
        margin-left: 50px;
        width: 450px;
        height: 90vh;
    }

    .paragraph {
        padding-right: 90px;
        margin-right: 80px;
    }

    .heroContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 30px;
        width: 60%;
    }

    .emptyContainer {
        width: 40%;
        height: 90vh;
    }

    .altHeroContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        height: 90vh;
    }

    .imageBgc {
        width: 80%;
        height: 75%;
        left: 50%;
        transform: translate(-60%, -50%);
    }

    .heroImg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 99%;
    }
}

@media (max-width: 850px) {
    .heading {
        font-size: 38px;
        line-height: 40px;
        font-weight: 700;
        color: #000;
        text-align: center;
        margin-top: 20px;
    }

    .paragraph {
        padding-right: 0px;
        margin-right: 0px;
        text-align: center;
    }

    .headingContainer {
        display: block;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        height: 20vh;
        margin-left: 5px;
        margin-right: 5px;
        padding-bottom: 15px;
        text-align: center;
        background-color: #fff;
    }

    .heroBackground {
        width: 100%;
        height: 60vh;
        padding-top: 150px;
        background: linear-gradient(0deg, #fff 0%, #fff 50%, #f15223 50%);
    }

    .heroImg {
        transform: translate(-45%, -50%);
        width: 89%;
    }

    .altHomeHeroInnerContainer {
        flex-direction: column;
        justify-content: center;
        padding-top: 0px;
        margin-top: 0px;
        width: 100%;
    }

    .imageContainer {
        position: relative;
        width: 100%;
        height: 50vh;
        left: 0;
        top: 10%;
        padding: 0;
    }

    .imageBgc {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 80%;
        background-color: #ffd685;
        z-index: 1;
        box-shadow: 3px 5px 3px 2px #5f5f5f;
    }

    .heroContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 30px;
        width: 50%;
    }

    .altHeroContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50vh;
    }

    .homeHeroInnerContainer {
        height: 90vh;
        margin-top: -150px;
        padding-top: 100px;
        padding-bottom: 100px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 1) 40%,
                rgba(255, 255, 255, 0.6) 50%,
                rgba(255, 255, 255, 0.4) 53%,
                rgba(255, 255, 255, 0.3) 54%,
                rgba(255, 255, 255, 0.2) 55%,
                rgba(255, 255, 255, 0.1) 56%,
                rgba(255, 255, 255, 0) 100%);
    
    }
}

@media (max-width: 500px) {

    .heroBgImage {
        background-image: url('../assets/images/DTPHerobig.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
        width: 100%;
        height: 55%;
        margin-top: 73px;
    }

    .heroBackground {
        width: 100%;
        max-height: 80vh;
        margin-top: 70px;
        margin-bottom: 50px;
        background: linear-gradient(0deg, #fff 0%, #fff 50%, #f15223 50%);
    }

    .imageContainer {
        position: relative;
        width: 100%;
        height: 40vh;
        left: 0;
        padding: 0;
    }

    .imageBgc {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 70%;
        background-color: #ffd685;
        z-index: 1;
        box-shadow: 3px 5px 3px 2px #5f5f5f;
    }

    .heroImg {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        width: 100%;
    }

    .altHeroContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 0vh;
    }

    .headingContainer {
        display: block;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        height: 20vh;
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        background-color: #fff;
    }

    .heading {
        font-size: 28px;
        line-height: 40px;
        font-weight: 700;
        color: #000;
        text-align: center;
        margin-top: 20px;
    }

    .emptyContainer {
        width: 100%;
        height: 40%;
    }

    .homeHeroInnerContainer {
        flex-direction: column;
        justify-content: center;
        padding-top: 10px;
        margin-top: 0px;
        padding-bottom: 50px;
        width: 100%;
        height: 50%;
        background: rgba(255, 255, 255, 0);
    }

    .heroContainer {
        display: block;
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        height: 50%;
        margin-top: 11%;
        padding-top: 17%;
        text-align: center;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 85%,
                rgba(255, 255, 255, 0.8) 87%,
                rgba(255, 255, 255, 0.7) 89%,
                rgba(255, 255, 255, 0.6) 90%,
                rgba(255, 255, 255, 0.4) 92%,
                rgba(255, 255, 255, 0.3) 94%,
                rgba(255, 255, 255, 0.2) 95%,
                rgba(255, 255, 255, 0.1) 96%,
                rgba(255, 255, 255, 0) 100%);
    }

    .paragraph {
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (max-width: 380px) {
        .heroBackground {
            width: 100%;
            height: 80vh;
            margin-top: 70px;
            margin-bottom: 10px;
            background: linear-gradient(0deg, #fff 0%, #fff 40%, #f15223 60%);
        }
    }

}