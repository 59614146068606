.mainContainer {
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 127px;
    padding-bottom: 127px;
}

.helpCard {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 25px;
    box-shadow: 5px 5px 5px 2px #6b7280;
}

.helpHeader {
    font-size: 40px;
    font-weight: 800;
    text-align: left;
    line-height: normal;
    padding-top: 24px;
    padding-bottom: 24px;
}

.contactFormContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 25px;
    box-shadow: 5px 5px 5px 2px #6b7280;
}

.contactFormHeader {
    font-size: 24px;
    font-weight: normal;
    text-align: left;
    margin-top: 24px;
    padding-bottom: 24px;
}

.goBackButton {
    font-size: 14px;
    background-color: #F15223;
    color: white;
    border-color: #F15223;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #6b7280;
    padding: 8px 14px;
    margin-bottom: 30px;
}

.buttonsContainer {
    width: 100%;
    display: grid;
    gap: 50px 100px;
    grid-template-columns: auto auto;

}

.helpButton {
    width: 100%;
    border: 2px solid gray;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 0.5px #6b7280;
    text-decoration: none;
    color: #F15223;
    font-weight: 700;
    background-color: #fff;
}

.helpButton:hover {
    border: 2px solid gray;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #F15223;
}

.helpButton div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 24px 8px;
}

.formGrid {
    display: grid;
    grid-template-columns: auto;
}

.formSelect {
    background-color: #F1F1F1;
    margin-bottom: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    border-color: transparent;
    border-radius: 5px;
    outline: #F15223;
}

.errorText {
    color: red;
    font-size: 12px;
    font-style: italic;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.sendButton {
    width: 40%;
    justify-self: center;
    border-color: transparent;
    border-radius: 10px;
    padding: 10px 15px;
    box-shadow: 2px 2px 2px 0.5px #6b7280;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    background-color: #F15223;
}

@media (max-width: 1025px){
    .mainContainer {
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .buttonsContainer {
        display: grid;
        gap: 20px 20px;
        grid-template-columns: auto auto;
    }

    .helpButton div{
        width: 90%;
    }

}


@media (max-width: 762px) {


    .mainContainer {
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .helpCard {
        width: 80%;
        padding-left: 25px;
        padding-right: 25px;
    }

    .helpHeader {
        font-size: 26px;
        text-align: center;
    }

    .contactFormContainer {
        width: 80%;
        padding-left: 25px;
        padding-right: 25px;
        margin-top: 30px;
    }

    .contactFormHeader {
        font-size: 18px;
        text-align: center;
    }

    .buttonsContainer {
        display: grid;
        gap: 20px 20px;
        grid-template-columns: auto;
    }
}