.main {
    padding-top: 50px;
    padding-bottom: 100px;
    padding-left: 127px;
    padding-right: 127px;
}

.heading {
    font-size: 44px;
    line-height: 52px;
    font-weight: 700;
}

.featureContainer {
    display: flex;
    flex-direction: row;
}


.contentContainer {
    width: 80%;
    padding-left: 30px;
    padding-left: 30px;
}

.featureTitle {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    text-align: left;
    line-height: normal;
    color: #000;
}

.featureContent {
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    line-height: 22px;
    padding-top: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #FFD685;
}

.featureGrid {
    display: grid;
    gap: 50px 100px;
    grid-template-columns: auto auto;
    margin-top: 100px;
}

.startTrackingButton {
    background-color: #F15223;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
    margin-right: 10px;
    padding: 12px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: none;
    border-radius: 25px;
    box-shadow: 1px 2px 6px 1px #373a3f;
    cursor: pointer;
    max-width: 70%;
}

@media (max-width: 762px) {

    .main {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .heading {
        font-size: 28px;
        line-height: 40px;
        font-weight: 700;
        text-align: center;
    }

    .featureContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .contentContainer {
        width: 100%;
        padding-left: 5px;
        padding-left: 5px;
    }

    .featureTitle {
        margin-top: 10px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
    }

    .featureContent {
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        text-align: center;
        padding-right: 10px;
        padding-left: 10px;
    }

    .buttonContainer {
        margin-top: 0px;
        text-align: center;
    }

    .featureGrid {
        display: grid;
        text-align: center;
        gap: 20px 50px;
        grid-template-columns: auto;
        margin-top: 50px;
    }
}