.logo {
  cursor: pointer;
}

.footerLogo{
  padding-left: 20px;
}

.navMainContainer {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99;
  padding-top: 3px;
  padding-bottom: 2px;
  box-shadow: 0 0 6px 1px #6b7280;
}

.desktopNavContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.footerNavContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.midFooterContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.desktopNavList {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 20px;
  margin-top: -22px;
  list-style-type: none;
  z-index: 99;
}

.desktopNavList li {
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.footerNavList {
  margin-left: 100px;
  margin-right: 100px;
  display: grid;
  gap: 0px 100px;
  grid-template-columns: auto auto auto auto auto auto;
}

.footerNavList li {
  list-style: none;
}

.link {
  color: black;
  font-weight: 600;
  text-decoration: none;
  font-size: 18px;
}

.link:hover {
  font-weight: 700;
  color: #F15223;
}

.navMobileButton {
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  width: 45px;
  height: 45px;
  background-color: #F15223;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border-color: #fff;
  z-index: 99;
}

.mobileNav {
  position: fixed;
  top: 11%;
  right: 10px;
  width: 60%;
  height: auto;
  padding-bottom: 20px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 2px #6b7280;
  background-color: #fff;
  z-index: 99;
}

.mobileNavMenuList {
  list-style: none;

}

.mobileNavMenuList li {
  margin-top: 20px;
}

.mobileNavItem {
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: #F15223;
  padding: 10px;
}

.hightLightText {
  color: #F15223
}

#closeNavMobile {
  font-size: 32px;
  font-family: sans-serif;
  color: white;
}

.mobileMenuIcon {
  width: 24px;
  height: 24px;
  color: #fff;
}

.socialMedia {
  display: flex;
  flex-direction: row;
  /* width: 10%; */
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
}

.socialIcon{
  padding: 5px 8px;
}

.featureMenuContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: #ddd solid 1px;
  border-radius: 10px;
  cursor: pointer;
}

.featureIcons {
  margin-left: 10px;
  width: 15px;
  height: 15px;
  color: #F15223;
  display: inline;
}

.dropDownContainer {
  background-color: #f8fafc;
  position: absolute;
  top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.dropDownItem {
  cursor: pointer;
  border-radius: 10px;
  padding: 4px;
  text-align: center;
  font-weight: 700;
}

.dropDownItem :hover {
  background-color: #F15223;
  color: white;
}

.dropDownItem a {
  text-decoration: none;
  text-align: center;
}

.mobileFeatureMenuContainer {
  position: relative;
}

.mobileDropDownContainer {
  background-color: #f8fafc;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  min-width: 150px;
  margin-top: 10px;
}

.mobileDropDownItem {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
  font-weight: 700;
}

.startTrackingButton {
  background-color: #F15223;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  margin-right: 10px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-style: none;
  border-radius: 25px;
  box-shadow: 1px 2px 6px 1px #373a3f;
  cursor: pointer;
}

.paragraph {
  font-size: 18px;
  text-align: left;
  font-weight: normal;
  line-height: 30px;
  color: #000;
}

@media (max-width: 1225px) {
  .footerNavContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 10px;
    padding-bottom: 32px;
  }

  .midFooterContainer {
    align-items: center;
  }

  .footerNavList {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
    margin-right: 0px;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: auto auto auto auto auto auto;
    margin-top: 0px;
  }

  .socialMedia {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
}

@media (max-width: 762px) {
  .navMainContainer {
    justify-content: space-between;
  }

  .footerNavList {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
    margin-right: 0px;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: auto auto auto;
    margin-top: 0px;
  }

}