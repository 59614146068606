.headingContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 30px;
}

.heading {
    font-size: 44px;
    font-weight: 700;
    line-height: 52px;
}

.stepNumberContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    margin-left: 30px;
    margin-bottom: -20px;
}

.stepNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    padding: 5px;
    background-color: #ffd685;
    font-size: 32px;
    font-weight: 600;
    color: #f4754f;
    border-radius: 50%;
}

.stepContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.stepInnerContainer {
    display: flex;
    flex-direction: column;
}

.worksStep {
    display: flex;
    width: 30%;
    justify-content: space-around;
    align-items: center;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.stepParagraph{
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    text-align: center;
    margin-left: 15px;
}

@media (max-width: 1024px) {
    .heading {
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
    }
}

@media (max-width: 762px) {
    .heading {
        font-size: 24px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .stepContainer {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        width: 100%;
    }

    .stepInnerContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .stepNumberContainer {
        display: flex;
        height: 100px;
        width: 30%;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        margin-bottom: 0px;
    }

    .stepNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        padding: 5px;
        background-color: #ffd685;
        font-size: 22px;
        font-weight: 600;
        color: #f4754f;
        border-radius: 50%;
    }

    .worksStep {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: center;
    }

    .contentContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 60%;
    }

    .stepParagraph{
        font-size: 20px;
        line-height: 34px;
        font-weight: 600;
        text-align: center;
        margin-left: 0;
    }

}