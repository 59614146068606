.mainPageContainer {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;
}

.fullInnerCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #6b7280;
}

.list {
    list-style: none;
    line-height: 28px;
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.dottedList {
    list-style: disc;
    line-height: 28px;
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.subDottedList{
    list-style: circle;
    line-height: 28px;
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.pageTitle {
    font-size: 42px;
    font-weight: normal;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.pageSubtitle {
    font-size: 24px;
    font-weight: normal;
    margin-top: 24px;
    text-align: left;
}

.sectionTitleOrange {
    font-size: 24px;
    font-weight: normal;
    margin-top: 24px;
    color: #F15223;
    text-align: left;
}

.sectionSubtitleOrange {
    font-size: 20px;
    font-weight: normal;
    margin-top: 15px;
    color: #F15223;
    text-align: left;
}

.linkOrange {
    font-weight: 600;
    color: #F15223;
}

@media (max-width: 1025px) {

    .mainPageContainer {
        margin-left: 25px;
        margin-right: 25px;
        padding-top: 50px;
        padding-bottom: 50px;
    }


    .fullInnerCard {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 762px) {

    .mainPageContainer {
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 50px;
        padding-bottom: 50px;
    }


    .fullInnerCard {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pageTitle {
        font-size: 24px;
        font-weight: normal;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .pageSubtitle {
        font-size: 18px;
        text-align: center;
    }

    .sectionTitleOrange {
        font-size: 18px;
        text-align: center;
    }

    .sectionSubtitleOrange {
        font-size: 16px;
        margin-top: 10px;
        text-align: center;
    }

    .list {
        font-size: 14px;
        margin-left: 15px;
    }

    .dottedList {
        font-size: 14px;
        margin-left: 15px;
    }

    .subDottedList{
        font-size: 14px;
        margin-left: 10px;
    }
    
}