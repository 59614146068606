.mainBgc {
  background-image: url('../assets/images/mapBg.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;

}

.welcomeHeading {
  font-size: 32px;
  font-weight: 800;
  text-align: left;
  line-height: normal;
}

.welcomeSecondHeading {
  font-size: 22px;
  font-weight: 800;
  text-align: left;
  line-height: normal;
  padding-bottom: 24px;
}

.familyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 65vh;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 100px;
  padding-left: 40px;
  margin-right: 40px;
  gap: 20px;
}

.familyHeaderContainer {
  margin-top: 50px;
}

.familyHeader {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #374151;
}

.familySubtitle {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 8px;
}


.downloadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 60px;
}

.boldParagraph {
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
}

.standardParagraph {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
}

.errorMessage {
  color: red;
  padding: 5px 20px 5px 25px;
}

.sendCodePanel {
  width: 650px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.smsPanelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  /* padding-left: 24px;
  padding-right: 24px; */
}

.orPanel {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.orfont {
  font-size: 22px;
  color: #F15223;
  font-weight: 600;
  text-align: center;
}

.smsPanelHeader {
  font-size: 16px;
  text-align: center;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.smsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.emailInput {
  width: 300px;
  background-color: #F1F1F1;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  outline: #F15223;
  border-radius: 10px;
  border-color: transparent;
}

.btn {
  font-size: 14px;
  background-color: #F15223;
  color: white;
  border-color: #F15223;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 2px #6b7280;
  padding: 8px 14px;
  margin-bottom: 30px;
  cursor: pointer;
}

.storeButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.storelink {
  text-align: center;
}

.storeLogo {
  width: 50%;
  height: auto;
}

.getStartedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #F15223;
  color: white;
  border-color: #F15223;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 2px #6b7280;
  padding: 8px 25px;
  margin-bottom: 30px;
}

.arrowIcon {
  display: inline-block;
  width: 24px;
  margin-left: 24px;
}

.groupCodePanelContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 200px;
  padding-top: 0;
  padding-bottom: 0;
  background: #fff;
  border-radius: 25px;
  box-shadow: 5px 5px 5px 2px #6b7280;
}

.codePanel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 350px;
  gap: 8px;
}


.groupCode {
  display: inline-block;
  /* min-width: 90%; */
  width: 90%;
  font-size: 32px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #56A52C;
  border-radius: 10px;
}

.copyIconContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  top: 20px;
  right: 20px;
}

.copyIcon {
  width: 30px;
  top: 24px;
  color: #F15223;
  cursor: pointer;
}

.copy {
  font-size: 10px;
  text-align: center;
  color: #F15223;
}

.redirectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-left: 40px;
  margin-right: 40px;
  gap: 20px;
}

@media (max-width: 762px) {

  .familyContainer {
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 8px;
  }

  .familyHeaderContainer {
    margin-top: 24px;
  }

  .familySubtitle {
    font-size: 14px;
    padding-bottom: 24px;
  }

  .emailInput {
    width: 90%;
  }

  .storeButtonsContainer {
    display: grid;
    gap: 20px;
    grid-template-columns: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .storeLogo {
    width: 100%;
    height: auto;
  }

  .groupCodePanelContainer {
    width: 100%;
    height: 200px;
    padding-top: 28px;
    padding-bottom: 28px;
  }

  .codePanel {
    width: 100%;
  }

  .groupCode {
    width: 75%;
    font-size: 24px;
  }

  .redirectContainer {
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 8px;
  }
}