.groupCodeMainBgc {
    display: flex;
    justify-content: center;
    background-image: url('../assets/images/mapBg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    /* margin-top: -120px;
    padding-top: 50px; */
}

.groupCodeMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin-top: 0;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 40px;
    padding-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
}

.headerConstainer {
    margin-top: 50px;
}

.boldParagraph {
    font-size: 32px;
    font-weight: 600;
    color: #374151;
    text-align: center;
    margin-bottom: 24px;
}

.standardParagraph {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: center;
    padding-bottom: 40px;
    padding-left: 8px;
    padding-right: 8px;
}

.emailFormContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 48px;
    padding: 20px 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #6b7280;
}

.emailFormHeader{
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    color: #374151;
    margin-bottom: 24px;
    padding-bottom: 8px;
}

.spinnerContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.bottomTextConatiner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}


@media (max-width: 762px) {

    .groupCodeMainContainer {
        margin-top: 12px;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 24px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .headerConstainer {
        margin-top: 24px;
    }

    .emailFormContainer {
        width: 100%;
        margin: 24px;
    }

.emailFormHeader{
    font-size: 14px;
}
}