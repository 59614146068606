.popup {
    position: absolute;
    width: 30%;
    top: 10vh;
    right: 15vw;
    padding: 25px 15px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 1px 2px 6px 1px #373a3f;
    z-index: 99;
}

.dismissContainer {
    display: flex;
    justify-content: flex-end;
}

.dismiss {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 7px;
    height: 7px;
    font-size: 7px;
    z-index: 5;
    padding: 7px 7px;
    background-color: #F15223;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    z-index: 99;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header{
    font-size: 24px;
    color: #F15223;
    font-weight: bold;
    margin-top: 0;
    text-align: left;
    font-weight: 600;
}

.headerIcon{
    width: 25%;
    height: auto;
}

.verificationContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
}

.verificationResult {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.verificationResult p {
    font-size: 16px;
    font-weight: normal;
    color: #000;
    margin-left: 5px;
}

.result {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.resultNetwork {
    font-size: 16px;
    font-weight: 600;
    color: #F15223;
}

.resultBlock{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkRactangle{
    width: 14px;
    height: 14px;
    background-color: #2d68ff;
    border-radius: 5px;
}

.featureList {
    list-style: none;
}

.featureList li {
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-top: 6px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 25px;
    font-size: 15px;
    text-align: left;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.newPrice {
    font-size: 48px;
    font-weight: bold;
    color: #000;
    margin-top: 10px;
    margin-bottom: 10px;
}

.newPriceOrange {
    font-size: 48px;
    font-weight: bold;
    color: #F15223;
    margin-top: 10px;
    margin-bottom: 10px;
}

.oldPrice {
    text-decoration: line-through;
    color: #ccc;
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.checkoutButton {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 10px;
    background-color: #F15223;
    box-shadow: 3px 5px 3px 2px #ddd;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.trustLogosContainer {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.trustLogo {
    width: 100%;
    height: auto;
}

.smallTrustLogo {
    width: 45%;
    height: auto;
}

.featureContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.feature {
    font-size: 14px;
    margin-left: 5px;
    text-align: left;
}

.featureIcon {
    width: 16px;
    color: #F15223;
}

.halfPriceBox {
    display: inline-block;
    background-color: #d9f99d;
    padding: 4px 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 10px;
    box-shadow: 3px 5px 3px 2px #ddd;
}

.halfPrice{
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #56A52C;
}

.startTrackingButton {
    background-color: #F15223;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
    margin-right: 10px;
    padding: 12px;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 30px;
    border-style: none;
    border-radius: 25px;
    box-shadow: 1px 2px 6px 1px #373a3f;
    cursor: pointer;
}

@media (max-width: 762px) {
    .main {
        margin-left: 10px;
        margin-right: 10px;
    }

    .popup {
        max-width: 100%;
        top: 20px;
        right: 10px;
        left: 10px;
    }

    .header{
        font-size: 22px;
    }

    .price {
        text-align: center;
        align-items: center;
    }

    .result {
        font-size: 14px;
    }
    .verificationResult p {
        font-size: 14px;
        font-weight: normal;
        color: #000;
        margin-left: 5px;
    }

    .priceContainer {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .featureContainer {
        justify-content: center;
      }

}

@media (min-width: 1550px) {
    .popup {
        max-width: 30%;
        top: 21vh;
        right: 20vw;
    }
  }