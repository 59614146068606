.mainBgc {
    background-image: url('../assets/images/mapBg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -100px;
    min-height: 90vh;
}

.mainContainer {
    padding-top: 150px;
    margin-left: 127px;
    margin-right: 127px;
}

.heroHeading {
    font-size: 42px;
    line-height: 55px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 32px;
    color: #F15223;
}

.smallHeading {
    font-size: 24px;
    font-weight: bold;
    line-height: 45px;
    text-align: left;
    margin-bottom: 32px;
    margin-right: 75px;
}

.gridContainer {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
}

ul li {
    margin-top: 24px;

}

ul li:hover{
    font-weight: 700;
}

li a{
    color: #000;
}

li a:hover{
    color: #F15223;
}

li p{
    display: inline;
    font-weight: normal;
}

@media (max-width: 762px) {

    .mainContainer {
        padding-top: 100px;
        margin-left: 5px;
        margin-right: 5px;

    }

    .heroHeading {
        font-size: 24px;
        line-height: 48px;
        text-align: center;
    }

    .smallHeading {
        font-size: 20px;
        line-height: 38px;
        text-align: center;
        margin-right: 0px;
    }

    .gridContainer {
        display: grid;
        gap: 0px;
        grid-template-columns: auto;
    }

    .directionImage{
        width: 100%;
    }
}