.main {
    background-color: #f15223;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 127px;
    padding-right: 127px;
}

.heading {
    font-size: 38px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: #fff;
}

.buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
}

.button {
    background-color: #fff;
    text-decoration: none;
    color: #f15223;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    padding: 12px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: none;
    border-radius: 25px;
    box-shadow: 1px 2px 6px 1px #373a3f;
    cursor: pointer;
}

.testimonialContainer {
    min-height: 250px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
}


@media (max-width: 762px) {

    .main {
        background-color: #f15223;
        padding-top: 30px;
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .heading {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        color: #fff;
    }

    .testimonialContainer {
        display: flex;
        flex-direction: column;
        margin: 30px;
        text-align: center;
        justify-content: center;
        background-color: #fff;
    }

    .buttonContainer {
        background-color: transparent;
        text-align: center;
        border: none;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .bottomButton {
        background-color: transparent;
        text-align: center;
        border: none;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

}