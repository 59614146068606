.bgSlade {
    height: 90hv;
    background-color: #f8fafc;
    padding-bottom: 20px;
    padding-bottom: 50px;
    padding-left: 127px;
    padding-right: 127px;
}

.main {
    height: 90hv;
    background-color: #fff;
    margin-bottom: 50px;
    margin-left: 127px;
    margin-right: 127px;
    margin-top: 30px;
}

.heroImage {
    resize: contain;
}

.heroContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trackSubtitle {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    text-align: left;
    list-style: none;
}

.popup {
    position: absolute;
    top: 50px;
    right: 50px;
    padding: 50px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 3px 5px 3px 2px #ddd;
}

.formContainer {
    text-align: center;
    width: 90%;
    margin-top: 30px;
}

.inputContainer {
    display: flex;
    margin-top: 5px;
    justify-content: flex-start;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 25px;
    box-shadow: 2px 3px 3px 3px #ddd;
}

.form {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.formHeader{
    font-size: 16px;
    color: black;
}

.inputField {
    width: 95%;
    margin-top: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    background-color: #f8fafc;
    border-radius: 5px;
    font-size: 16px;
}

.inputField:focus {
    outline: none;
}

.btn {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    border-style: none;
    background-color: #F15223;
    color: #fff;
    cursor: pointer;
    ;
}

.featuremainContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 650px;
    padding-top: 50px;
}

.featureCarouselContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-top: -20px;
}

.trackFeatureHeading {
    font-size: 32px;
    line-height: 42px;
    font-weight: 800;
    text-align: left;
}

.featureGrid{
    display: grid;
    gap: 50px 100px;
    grid-template-columns: auto auto;
    margin-top: 100px;
}

.feature {
    width: 50%;
}

.featureTextContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-top: -80px;
    margin-right: 150px;
}

.featureTitle {
    font-size: 24px;
    font-weight: 800;
    text-align: left;
    line-height: normal;
    color: #F15223;
    margin-bottom: 24px;
}

.featureContent {
    font-size: 18px;
    text-align: left;
    font-weight: normal;
    line-height: 32px;
    padding-top: 10px;
}

.stepContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.worksStep {
    display: flex;
    width: 30%;
    justify-content: space-between;
    align-items: center;
}

.testimonialContainer {
    min-height: 150px;
    text-align: left;
    width: 80%;
}

.bottomHeaderContainer {
    margin-top: 50px;
}

.featureImageContainer {
    margin-top: 30px;

}

.buttonContainer{
    margin-top: 50px;
}

.skip{
    display: flex;
    flex-direction: row;
    color: #3b82f6;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.arrow{
    display: inline-block;
    margin-left: 8px;
    width: 15px;
}

@media (max-width: 762px) {
    .main {
        margin-left: 10px;
        margin-right: 10px;
    }

    .bgSlade {
        padding-left: 10px;
        padding-right: 10px;
    }

    .formContainer {
        margin-left: 10px;
        width: 95%;
    }

    .formHeader{
        font-size: 14px;
        color: black;
    }

    .trackFeatureHeading {
        margin-left: 5px;
        margin-right: 5px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 800;
        text-align: center;
    }

    .featureCarouselContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 0;
    }

    .featureTextContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        width: 100%;
        margin-top: -50px;
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .featuremainContainer {
        width: 100%;
        padding-top: 50px;
    }

    .feature {
        text-align: center;
        width: 100%;
        margin-top: 25px;
    }

    .featureGrid{
        display: grid;
        text-align: center;
        gap: 50px 50px;
        grid-template-columns: auto;
        margin-top: 50px;
    }
    

    .worksStep {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
    }


    .stepContainer {
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .popup {
        top: 100px;
        right: 15px;
        left: 15px;
    }

    .testimonialContainer {
        min-height: 150px;
        text-align: center;
        width: 100%;
    }

    .featureTitle {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }

    .featureContent {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: -20px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .featureImageContainer {
        width: 100%;
        display: flex;
        margin-top: -10px;
        justify-content: center;
        height: auto;
        margin-left: 25%;
    }

    .trackSubtitle {
        width: 100%;
        font-size: 16px;
        text-align: center;
    }
    .buttonContainer{
        margin-top: 50px;
        text-align: center;
    }
}

@media (max-width: 395px) {
  
    .featureImageContainer {
      width: 100%;
      display: flex;
      margin-left: 25%;
      justify-content: center;
      margin-bottom: 0px;
      height: auto;
    }
  }