.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    margin-left: 127px;
    margin-right: 127px;
    padding-top: 50px;
    padding-bottom: 127px;
}

.cancelCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    align-items: center;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #6b7280;
    padding: 20px 50px;
}

.cancelHeading {
    font-size: 40px;
    font-weight: 800;
    text-align: center;
    line-height: normal;
    padding-top: 24px;
    padding-bottom: 24px;
}

.cancelSubtitle {
    font-size: 32px;
    text-align: center;
    font-weight: normal;
}

.cancelSpinnerContainer {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.errorMessageContainer{
    padding: 24px 28px;
    border-radius: 10px;
}

.errorMessage {
    color: red;
    padding: 5px 20px 5px 25px;
}


.emailForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emailInput {
    width: 300px;
    background-color: #F1F1F1;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    outline: #F15223;
    border-radius: 10px;
    border-color: transparent;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    background-color: #F15223;
    color: white;
    border-color: #F15223;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #6b7280;
    padding: 8px 25px;
    margin-top: 50px;
    text-decoration: none;
    margin-bottom: 30px;
}


@media (max-width: 762px) {
    .mainContainer {
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .cancelCard {
        width: 95%;
        padding: 20px 2px;
    }

    .cancelHeading {
        font-size: 26px;
    }

    .cancelSubtitle {
        font-size: 22px;
    }

}