.pageContainer {
    margin-top: 50px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.topSectionContainer {
    position: relative;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: auto auto;
    justify-items: center;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
}

.lighting {
    position: absolute;
    display: block;
    top: 20px;
    left: 0px;
    z-index: -99;
}


.mtRegular {
    margin-top: 50px;
}


.firstHeader {
    font-size: 42px;
    font-weight: 800;
    line-height: normal;
    padding-bottom: 30px;
    text-align: left;
    padding-left: 100px;
}

.firstDescription {
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    line-height: 24px;
    padding-top: 12px;
    color: #4b5563;
    padding-left: 100px;
}

.hightLightText {
    color: #F15223;
    text-decoration: underline;
}

.firstImageContainer {
    position: relative;
}

.reyes {
    position: absolute;
    display: block;
    top: 15px;
    right: 20px;
}

.heroImage {
    width: 460px;
    height: auto;
}

.smallreys {
    position: absolute;
    display: block;
    bottom: -45px;
    left: -45px;
}

.vitalsContainer {
    position: relative;
    background-color: #FBFBFB;
    width: 100%;
    min-height: 500px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.hand {
    position: absolute;
    top: 0;
    left: 0;
}

.screen {
    position: absolute;
    display: block;
    bottom: 0;
    right: 0px;
}

.vitalsHeader {
    font-size: 40px;
    font-weight: 800;
    line-height: normal;
    padding-top: 50px;
    text-align: center;
}

.vitalsFeaturesContainer {
    display: grid;
    gap: 10px 10px;
    grid-template-columns: auto auto auto auto;
    justify-content: space-around;
    margin-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
}

.vitalsFeatureBox {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    box-shadow: 5px 5px 5px 2px #6b7280;
    padding: 12px;
}

.vitalDescription {
    text-align: center;
    font-weight: normal;
    font-size: 16px;
}

.vitalTitle {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.vitalSubTitle {
    text-align: center;
    font-weight: bold;
    font-size: 41px;
    color: #F15223;
}

.missionSectionContainer {
    position: relative;
    display: grid;
    gap: 10px 30px;
    grid-template-columns: auto auto;
    justify-items: center;
    padding-left: 50px;
    padding-right: 50px;
}

.zigzag {
    position: absolute;
    display: block;
    top: 200px;
    left: 0;
}

.missionTitle {
    text-align: left;
    font-weight: bold;
    font-size: 41px;
    padding-left: 100px;
}

.secondaryTitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: normal;
    color: #4b5563;
    text-align: left;
    margin-top: 50px;
    padding-left: 100px;
}

.splash {
    position: absolute;
    display: block;
    top: -45px;
    left: -60px;
}

.stars {
    position: absolute;
    display: block;
    bottom: -45px;
    right: -75px;
}

.bottomContainer {
    padding-top: 50px;
    padding-bottom: 50px;
}

.bottomIconsContainer {
    margin-top: 20px;
    display: flex;
    position: relative;
}

.leftArrow {
    position: absolute;
    display: block;
    top: 0;
    right: 200px;
}

.rightArrow {
    position: absolute;
    display: block;
    top: 0;
    left: 200px;
}

@media (max-width: 1025px) {

    .pageContainer {
        margin: 15px;
    }

    .topSectionContainer {
        display: grid;
        grid-template-columns: auto;
        padding-left: 12px;
        padding-right: 12px;
    }

    .vitalsFeaturesContainer {
        display: grid;
        gap: 50px 50px;
        grid-template-columns: auto auto;
    }

    .missionTitle {
        text-align: center;
    }

    .firstImageContainer {
        margin-bottom: 50px;
    }

    .firstHeader{
        text-align: center;
        margin-right: 70px;
    }

    .secondaryTitle {
        text-align: center;
        margin-right: 70px;
    }

    .firstDescription {
        text-align: center;
        margin-right: 70px;
    }
}

@media (max-width: 762px) {

    .pageContainer {
        margin: 15px;
    }

    .topSectionContainer {
        display: grid;
        grid-template-columns: auto;
        padding-left: 12px;
        padding-right: 12px;
    }

    .lighting {
        display: none;
    }

    .mtRegular {
        margin-top: 8px;
    }

    .firstHeader {
        font-size: 28px;
        padding-bottom: 30px;
        text-align: center;
        padding-left: 0px;
        margin-right: 0;
    }

    .firstDescription {
        font-size: 14px;
        text-align: center;
        padding-left: 0;
        margin-right: 0;
    }

    .firstImageContainer {
        display: flex;
        justify-content: center;
    }

    .reyes {
        display: none;
    }

    .heroImage {
        width: 80%;
        height: auto;
    }

    .smallreys {
        display: none;
    }

    .vitalsContainer {
        position: relative;
        background-color: #FBFBFB;
        width: 100%;
        min-height: 700px;
        padding-left: 12px;
        padding-right: 12px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .vitalsFeatureBox {
        margin-bottom: 20px;
    }

    .screen {
        display: none;
    }

    .vitalsHeader {
        font-size: 32px;
    }

    .vitalsFeaturesContainer {
        display: grid;
        gap: 10px 10px;
        grid-template-columns: auto;
        padding-right: 20px;
        padding-left: 20px;
    }

    .vitalSubTitle {
        font-size: 32px;
    }

    .missionSectionContainer {
        grid-template-columns: auto;
        padding-left: 12px;
        padding-right: 12px;
    }

    .zigzag {
        display: none;
    }

    .missionTitle {
        text-align: center;
        font-weight: bold;
        font-size: 32px;
        padding-left: 0px;
    }

    .secondaryTitle {
        font-size: 20px;
        font-weight: normal;
        color: #4b5563;
        text-align: center;
        padding-left: 0px;
        margin-right: 0;
    }

    .splash {
        display: none;
    }

    .stars {
        display: none;
    }

    .leftArrow {
        display: none;
    }

    .rightArrow {
        display: none;
    }
}