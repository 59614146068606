.fullInnerCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 2px #6b7280;
}

.mainContainer {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;
}

.listContainer {
    padding-right: 50px;
    margin-top: 24px;
}

.list {
    list-style: decimal;
    line-height: 28px;
    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.subList {
    list-style: decimal;
    line-height: 20px;
    font-size: 16px;
    padding-left: 40px;
    padding-right: 20px;
}

.dataSubList {
    list-style: decimal;
    line-height: 20px;
    font-size: 16px;
    padding-left: 40px;
    padding-right: 20px;
    color: #000;
}

.dataSubList li{
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: normal;
    color: #000;
}

.dataSubList span{
    font-size: 17px;
    font-weight: 600;
    color: #F15223;
}

.subUnnumList {
    list-style: none;
    line-height: 20px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
}

.dataCollectionList {
    font-size: 18px;
    font-weight: 600;
    margin-top: 24px;
    text-align: left;
    list-style: decimal;
}

.pageTitle {
    font-size: 42px;
    font-weight: normal;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pageSubtitle {
    font-size: 24px;
    font-weight: normal;
    margin-top: 24px;
    text-align: left;
}

.sectionTitleOrange {
    font-size: 24px;
    font-weight: normal;
    margin-top: 24px;
    color: #F15223;
    text-align: left;
}

.sectionSubtitle {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
}

.linkOrange {
    font-weight: 600;
    color: #F15223;
}

.contactDescription {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
}

.contactTable {
    table-layout: auto;
    border: 1px solid #F1F1F1;
    font-size: 16px;
    padding: 10px 10px;
}

.contactTableRowTitle {
    border: 1px solid #F1F1F1;
    text-align: center;
    padding: 8px 20px;
}

.contactTableRowContent {
    border: 1px solid #F1F1F1;
    padding: 8px 20px;
    padding-left: 8px;
    text-align: center;
}

.tdBody {
    border: 1px solid #F1F1F1;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.dottedList {
    list-style: disc;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 15px;
    font-size: 16px;
}

.dottedList li {
    padding-top: 10px;
    color: #000;
    font-weight: normal;
}

.subDottedList {
    list-style: disc;
    padding-left: 70px;
    padding-right: 50px;
    margin-top: 24px;
    padding-bottom: 24px;
    font-size: 16px;
}

.dottedList span {
    font-size: 17px;
    font-weight: 600;
    color: #F15223;
}

@media (max-width: 1025px) {
    .mainContainer {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (max-width: 762px) {
    .fullInnerCard {
        padding-left: 8px;
        padding-right: 8px;
    }

    .mainContainer {
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .listContainer {
        /* width: 100%; */
        padding-left: 5px;
        padding-right: 5px;
        margin-top: 24px;
    }

    .list {
        font-size: 14px;
    }

    .subList {
        font-size: 14px;
    }

    .subUnnumList {
        font-size: 14px;
    }

    .dottedListOrangeBold {
        font-size: 13px;
    }

    .pageTitle {
        font-size: 24px;
        font-weight: normal;
        text-align: center;
    }

    .pageSubtitle {
        font-size: 18px;
        text-align: center;
    }

    .sectionTitleOrange {
        font-size: 18px;
        text-align: center;
    }

    .contactDescription {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }


    .sectionSubtitle {
        font-size: 14px;
        text-align: center;
    }

    .contactTable {
        font-size: 13px;
    }

    .contactTableRowTitle {
        padding: 8px 2px;
    }

    .contactTableRowContent {
        padding: 8px 2px;
    }

    .tdBody {
        text-align: center;
    }

    .dottedList {
        font-size: 13px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .subDottedList {
        list-style: disc;
        padding-left: 15px;
        padding-right: 10px;
        margin-top: 15px;
        padding-bottom: 15px;
        font-size: 13px;
    }

    .dottedList span {
        font-size: 15px;

    }

    .dataCollectionList {
        font-size: 16px;
        text-align: center;
    }

    .dataSubList {
        font-size: 13px;
        padding-left: 20px;
    }
    
    .dataSubList span{
        font-size: 15px;

    }
}