.mainContainer{
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 127px;
    padding-bottom: 127px;
}
.mainPageCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 25px;
    box-shadow: 5px 5px 5px 2px #6b7280;
}



@media (max-width: 762px) {
    .mainContainer{
        margin-left: 20px;
        margin-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .mainPageCard {
        padding-left: 12px;
        padding-right: 12px;
    }



}