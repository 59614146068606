.mainSection {
    /* width: 100vw; */
    background-color: #f4754f;
    background: linear-gradient(90deg, #f4754f 0%, #f4754f 50%, #fff 50%);
}

.maxWidth {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.featureCarouselContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-top: -25px;
}

.featureImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    width: 60%;
    height: 70vh;
}

.featureTextContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-right: 100px;
    padding-left: 100px;
}

.featureTitle {
    font-size: 42px;
    line-height: 58px;
    font-weight: 800;
    text-align: left;
    line-height: normal;
    color: #000;
    margin-bottom: 24px;
}

.featureContent {
    font-size: 22px;
    text-align: left;
    font-weight: normal;
    line-height: 44px;
    padding-top: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #FFD685;
}

.startTrackingButton {
    background-color: #F15223;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
    margin-right: 10px;
    padding: 12px;
    padding-left: 15px;
    padding-right: 15px;
    border-style: none;
    border-radius: 25px;
    box-shadow: 1px 2px 6px 1px #373a3f;
    cursor: pointer;
    max-width: 70%;
}

@media (max-width: 1299px) {
    .featureTextContainer {
        margin-right: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 1024px) {
    .featureTextContainer {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-right: 50px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .featureTitle {
        font-size: 38px;
        line-height: 42px;
        font-weight: 800;
        text-align: left;
        line-height: normal;
        color: #000;
        margin-bottom: 24px;
    }

    .featureContent {
        font-size: 18px;
        text-align: left;
        font-weight: normal;
        line-height: 32px;
        padding-top: 10px;
    }
}


@media (max-width: 762px) {
    .mainSection {
        width: 100vw;
        background-color: #f4754f;
        background: linear-gradient(90deg, #f4754f 0%, #f4754f 50%, #f4754f 50%);
    }

    .featureCarouselContainer {
        height: 100%;
        margin-top: 0;
        background-color: #f4754f;
    }

    .featureImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 100px;
        width: 20%;
    }

    .featureTextContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        width: 80%;
        margin-right: 0px;
        margin-bottom: 30px;
        padding: 10px;
    }

    .featureTitle {
        margin-top: 10px;
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
        text-align: left;
        color: #fff;
        margin-bottom: 20px;
    }

    .featureContent {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0px;
        padding-right: 10px;
        padding-left: 10px;
        color: #fff;
    }

    .buttonContainer {
        margin-top: 0px;
        text-align: center;
    }
}