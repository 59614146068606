* {
  margin: 0;
  padding: 0;
  font-family: "Inter";
}

body {
  position: relative;
}

.dtpContainer {
  margin-left: 127px;
  margin-right: 127px;
  padding-top: 127px;
  padding-bottom: 127px;
}

.smallVerticalMargin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.midVerticalMargin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.lgVerticalMargin {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mainBgc {
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  background-position: top center;
}

.maxScreen {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.mainContainer {
  padding-bottom: 50px;
}

.homeHeroInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.heroInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 70px;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}

.heroImageContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 50px;
}

.bottomContainer {
  background-image: url('./assets/images/bottom_bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;
  padding-bottom: 127px;
  padding-top: 50px;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 50px;
  width: 750px;
  height: 90vh;

}

.inputContainer{
  width: 60%;
}

.heroHeading {
  font-size: 40px;
  line-height: 55px;
  font-weight: 900;
  text-align: left;
}

.secondaryContainer {
  width: 650px;
  padding-top: 100px;
  padding-bottom: 30px;
}

.secondaryHeading {
  font-size: 28px;
  line-height: 42px;
  font-weight: 800;
  text-align: left;
}

.smallHeading {
  font-size: 24px;
  font-weight: 800;
  text-align: left;
}


.paragraph {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.parahraphCentered {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.smallParagraph {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

.fontWhite {
  color: #fff;
}

.hightLightText {
  color: #F15223;
}

.displayRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobileDisabled {
  display: block;
}

.marginLeft {
  margin-left: 30px;
}

.buttonContainer {
  display: flex;
  margin-top: 100px;
  margin-bottom: 50px;
  justify-content: flex-start;
}

.trackingButton {
  background-color: #F15223;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-style: none;
  border-radius: 25px;
  box-shadow: 3px 5px 3px 2px #ddd;
  cursor: pointer;
}

.regTrackingButton {
  background-color: #F15223;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-style: none;
  border-radius: 25px;
  cursor: pointer;
}

.bottomFeature {
  display: flex;
  text-decoration: none;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 36px;
  padding-bottom: 36px;
  border: #ddd 2px solid;
  border-radius: 10px;
  cursor: pointer;
  min-height: 120px;
}

.bottomGrid {
  display: grid;
  gap: 50px 100px;
  grid-template-columns: auto auto;
}

.bottomFeatureTitle {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}

.bottomFeatureParagraph {
  font-size: 16px;
  color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}

.spinner {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #F15223;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.react-multi-carousel-list {
  position: unset !important;
}

.carousel__slider-tray-wrapper {
  padding-bottom: 90% !important;
}

.testimonials_buttonContainer__a\+sHD .carousel__back-button {
  margin-top: unset !important;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1025px) {

  .mainContainer {
    padding-bottom: 50px;
  }

  .heroInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .heroContainer {
    width: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    margin-left: 5%;
    margin-right: 10px;
    margin-top: 0;
  }

  .heroImageContainer {
    margin-top: 150px;
    padding-bottom: 150px;
    margin-right: 0;
  }

  .heroHeading {
    font-size: 32px;
    line-height: 42px;
    font-weight: 800;
    text-align: left;
  }

  .secondaryContainer {
    width: 100%;
    padding-top: 50px;
  }

  .smallHeading {
    text-align: center;
  }

  .secondaryHeading {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
  }

  .bottomGrid {
    display: grid;
    gap: 50px 0px;
    grid-template-columns: auto;
  }
}

@media (max-width: 768px) {

  .mainContainer {
    padding-top: 0px;
  }

  .mainBgc {
    margin-top: 20px;
  }

  .homeHeroInnerContainer {
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
  }

  .heroInnerContainer {
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
  }
  .inputContainer{
    width: 100%;
  }
  .dtpContainer {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .heroImageContainer {
    width: 100%;
    margin-top: 50px;
    padding-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    margin-right: 20%;
  }

  .bottomContainer {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 127px;
  }

  .heroContainer {
    width: 100%;
    padding-top: 150px;
    margin-left: 10px;
    margin-right: 10px;
    padding-right: 0;
    height: 50vh;
    padding-bottom: 5px;
    margin-top: 0;
    text-align: center;
  }

  .heroHeading {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }

  .smallHeading {
    text-align: center;
  }

  .secondaryContainer {
    width: 100%;
    padding-top: 50px;
  }

  .secondaryHeading {
    font-size: 18px;
    line-height: 30px;
    font-weight: 800;
    text-align: center;
  }

  .mobileDisabled {
    display: none;
  }

  .paragraph {
    text-align: center;
  }

  .marginLeft {
    margin-left: 0px;
  }

  .buttonContainer {
    margin-top: 100px;
    margin-bottom: 50px;
    justify-content: center;
  }

  .bottomFeature {
    gap: 4px;
  }

  .bottomGrid {
    display: grid;
    gap: 50px 0px;
    grid-template-columns: auto;
  }

  .bottomFeatureTitle {
    font-size: 17px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .bottomFeatureParagraph {
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }


}

@media (max-width: 395px) {

  .mainContainer {
    padding-top: 0;
  }

  .heroInnerContainer {
    flex-direction: column;
    padding-top: 0;
  }

  .heroImageContainer {
    width: 100%;
    display: flex;
    margin-left: 15%;
    justify-content: flex-end;
  }
}